import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../constant/Index";
import { location_icon } from "../constant/Index";
import { phone_call_icon } from "../constant/Index";
import { envelope } from "../constant/Index";
const Footer = () => {
	return (
		<>
			{/* cta sec starts here */}
			<section className="cta-sec">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="cta-content-wrapper">
								<h2 className="mb-0">Follow us on Social Media</h2>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="social-links-wrapper">
								<ul>
									<li>
										<a href="#">
											<i className="fa fa-instagram"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i className="fa fa-youtube-play"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i className="fa fa-facebook"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i class="fa fa-reddit-alien"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i className="fa fa-twitter"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* cta sec ends here */}
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="footer-widget-wrapper">
								<div className="logo-wrapper">
									<figure>
										<img src={Logo} alt="" />
									</figure>
								</div>
								<div className="logo-content-wrapper">
									<p>
										Vitae semper quis lectus nulla at volutpat diam ut.
										Adipiscing elit pellentesque habitant morbi tristique. Sem
										integer vitae.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="footer-widget-2-wrapper">
								<div className="footer-heading-wrapper">
									<h4>Menu</h4>
								</div>
								<div className="footer-menu-wrapper">
									<ul>
										<li>
											{/* <a href="#">Home</a> */}
											<Link to="/">Home</Link>
										</li>
										<li>
											{/* <a href="#">About Us</a> */}
											<Link to="/about">About Us</Link>
										</li>
										<li>
											{/* <a href="#">Products</a> */}
											<Link to="/product1">Products</Link>
										</li>
										<li>
											{/* <a href="#">Services</a> */}
											<Link to="/services">Services</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-widget-3-wrapper">
								<div className="footer-heading-wrapper">
									<h4>Quick links</h4>
								</div>
								<div className="footer-menu-wrapper">
									<ul>
										<li>
											{/* <a href="#">FAQ's</a> */}
											<Link to="/faqs">FAQ's</Link>
										</li>
										<li>
											{/* <a href="#">Terms & Cinditions</a> */}
											<Link to="/terms">Terms & Cinditions</Link>

										</li>
										<li>
											{/* <a href="#">Privacy Policy</a> */}
											<Link to="/privacy">Privacy Policy</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-widget-4-wrapper">
								<div className="footer-heading-wrapper">
									<h4>Contact</h4>
								</div>
								<div className="footer-menu-wrapper">
									<ul>
										<li>
											<a href="mailto:info@evx.com">
												<img src={envelope} />{" "}
												<span>info@evx.com</span>
											</a>
										</li>
										<li>
											<a href="tel:001234567890">
												{" "}
												<img src={phone_call_icon} />{" "}
												<span>00 123 456 7890</span>
											</a>
										</li>
										<li>
											<a href="#">
												{" "}
												<img src={location_icon} />{" "}
												<span>Lorem ipsum, dolor sit amet, 12345</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div id="copyright">
						<div className="row">
							<div className="col-lg-12">
								<p>Copyrights © 2022 Flit. All rights reserved.</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
