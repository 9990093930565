import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../constant/Index";
import { profile_img } from "../constant/Index";

const Header = () => {
	const [view, setView] = useState(false);
	useEffect(()=>{
	  window.scrollTo(0,0);
		},[])
	let currentUrl = window.location.href.split("/")
	console.log(currentUrl?.[3])
	return (
		<>
			<div id="headerTop">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<div className="logoBox">
							<Link to="/">
							<figure>
									<img src={Logo} alt="" className="img-fluid" />
								</figure>
							</Link>
							</div>
						</div>
						<div className="col-lg-9">
							<div className="navbar-menu">
								<ul className="menus">
									<li 
									className={`${
										currentUrl?.[3] === "" ? "active menu-item" : "menu-item"
									  }`}
									 >
										{/* <a href="#" className="menu-link active">
											Home
										</a> */}
										<Link to="/">Home</Link>
									</li>
									<li 
										className={`${
											currentUrl?.[3] === "about" ? "active menu-item" : "menu-item"
										  }`}
									>
										{/* <a href="#" className="menu-link">
											About Us
										</a> */}
										<Link to="/about">About Us</Link>
									</li>
									<li className={`${
											currentUrl?.[3] === "product1" ? "active menu-item" : "menu-item"
										  }`}>
										{/* <a href="#" className="menu-link">
											Products
										</a> */}
										<Link to="/product1">Products</Link>
									</li>
									<li className={`${
											currentUrl?.[3] === "services" ? "active menu-item" : "menu-item"
										  }`}>
										{/* <a href="#" className="menu-link">
											Services
										</a> */}
										<Link to="/services">Services</Link>
									</li>
									{/* <li className="menu-item">
										<a href="/contact" className="menu-link">
											Contact Us
										</a>
									</li> */}
									<li 
										className={`${
											currentUrl?.[3] === "contact" ? "active menu-item" : "menu-item"
										  }`}
									>
										{/* <a href="#" className="menu-link">
											Contact Us
										</a> */}
										<Link to="/contact">Contact Us</Link>
									</li>
									<li className="menu-item button">
										<Link to="/login" className="signin">Sign In</Link>
									</li>
									
									<li className="menu-item button">
										<Link to="/applyforloan">Apply Loan</Link>
									</li>
									<li className="menu-item d-none">
										<Link to="/login" className="signin"><img src={ profile_img } /></Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
