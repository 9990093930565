import React from "react";
import {
    bannerBike,
    BannerSlide1,
    Benefit1,
    Benefit2,
    Benefit3,
    Benefit4,
    Easy1,
    Easy2,
    EasyBefore,
    LeftBefore,
    m1,
    m2,
    m3,
    m4,
    m5,
    m6,
    Product1,
    Product2,
    Product3,
    Product4,
    ReviewImg1,
    ReviewImg2,
    ReviewImg3,
    ReviewImg4,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../assets/Css/services.css';

const Services = () => {

    let navigate = useNavigate();

    return (
        <div className="services">
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Easy Plan Sec Start Here */}
            <section className="easy-plan abcdef">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container mt-5">
                    <div className="row mb-5">
                        <div className="col-lg-12 text-left">
                            <p>Home <span className="fa fa-chevron-right chevron_right">  </span> Services</p>
                        </div>
                    </div>
                    <div className="heading-wrapper pb-4">
                        <h2>
                            Our Premium <span className="icon"></span>
                            {/* <br /> */}
                            <span className="colored">Services</span>
                        </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                            enim ad minim veniam, quis nostrud exercitation ullamco Laboris
                            consequat.
                        </p>
                    </div>
                </div>
            </section>
            {/* Easy Plan Sec End Here */}

            {/* services section starts */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 my-3">
                            <img className="w-100" src={m1} onClick={()=>navigate("/innerservices")} alt="services" />
                            <h4 className="serviceh4 my-3">Lorem ipsum dolor sit amet, consecte
                                adipiscing elit, sed do eiusmod</h4>
                            <p>Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris</p>
                            <p onClick={()=>{navigate('/innerservices')}} className="lear_more">Lear More</p>
                        </div>
                        <div className="col-lg-4 my-3">
                            <img className="w-100" src={m2}  onClick={()=>navigate("/innerservices")} alt="services" />
                            <h4 className="serviceh4 my-3">Lorem ipsum dolor sit amet, consecte
                                adipiscing elit, sed do eiusmod</h4>
                            <p>Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris</p>
                            <p onClick={()=>{navigate('/innerservices')}} className="lear_more">Lear More</p>
                        </div>
                        <div className="col-lg-4 my-3">
                            <img className="w-100" src={m3}  onClick={()=>navigate("/innerservices")} alt="services" />
                            <h4 className="serviceh4 my-3">Lorem ipsum dolor sit amet, consecte
                                adipiscing elit, sed do eiusmod</h4>
                            <p>Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris</p>
                            <p onClick={()=>{navigate('/innerservices')}} className="lear_more">Lear More</p>
                        </div>
                        <div className="col-lg-4 my-3">
                            <img className="w-100" src={m4}  onClick={()=>navigate("/innerservices")} alt="services" />
                            <h4 className="serviceh4 my-3">Lorem ipsum dolor sit amet, consecte
                                adipiscing elit, sed do eiusmod</h4>
                            <p>Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris</p>
                            <p onClick={()=>{navigate('/innerservices')}} className="lear_more">Lear More</p>
                        </div>
                        <div className="col-lg-4 my-3">
                            <img className="w-100" src={m5}  onClick={()=>navigate("/innerservices")} alt="services" />
                            <h4 className="serviceh4 my-3">Lorem ipsum dolor sit amet, consecte
                                adipiscing elit, sed do eiusmod</h4>
                            <p>Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris</p>
                            <p onClick={()=>{navigate('/innerservices')}} className="lear_more">Lear More</p>
                        </div>
                        <div className="col-lg-4 my-3">
                            <img className="w-100" src={m6}  onClick={()=>navigate("/innerservices")} alt="services" />
                            <h4 className="serviceh4 my-3">Lorem ipsum dolor sit amet, consecte
                                adipiscing elit, sed do eiusmod</h4>
                            <p>Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris</p>
                            <p onClick={()=>{navigate('/innerservices')}} className="lear_more">Lear More</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* services section ends */}

            {/* Benefits Sec Start Here */}
            <section className="benefits">
                <div className="container">
                    <div className="universal-heading">
                        <h2>
                            <span className="colored">Benefits</span> that we Provide
                        </h2>
                        <p>
                            Est velit egestas dui id ornare arcu odio ut sem. Congue nisi
                            vitae suscipit tellus mauris a. Fringilla phasellus faucibus
                            scelerisque eleifend donec pretium vulputate sapien. Mus mauris
                            vitae ultricies leo integer malesuada. Amet venenatis urna cursus
                            eget nunc scelerisque viverra.
                        </p>
                    </div>
                    <div className="benefits-box">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="benefit-dv">
                                    <div className="icon-dv">
                                        <figure>
                                            <img src={Benefit1} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="txt">
                                        <h3>Best Quality</h3>
                                        <p>
                                            Est velit egestas dui id ornare arcu odio ut sem. Congue
                                            nisi vitae suscipit tellus.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="benefit-dv">
                                    <div className="icon-dv">
                                        <figure>
                                            <img src={Benefit2} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="txt">
                                        <h3>Reliable Service</h3>
                                        <p>
                                            Est velit egestas dui id ornare arcu odio ut sem. Congue
                                            nisi vitae suscipit tellus.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="benefit-dv">
                                    <div className="icon-dv">
                                        <figure>
                                            <img src={Benefit3} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="txt">
                                        <h3>24/7 Support</h3>
                                        <p>
                                            Est velit egestas dui id ornare arcu odio ut sem. Congue
                                            nisi vitae suscipit tellus.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="benefit-dv">
                                    <div className="icon-dv">
                                        <figure>
                                            <img src={Benefit4} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="txt">
                                        <h3>Trusted Anywhere </h3>
                                        <p>
                                            Est velit egestas dui id ornare arcu odio ut sem. Congue
                                            nisi vitae suscipit tellus.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Benefits Sec End Here */}

            {/* Client Reviews Sec Start Here */}
            <section className="client-review">
                <div className="before">
                    <figure>
                        <img src={LeftBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container">
                    <div className="universal-heading">
                        <h2>
                            Have a look at our <span className="colored">Client Reviews</span>
                        </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                            enim ad minim veniam, quis nostrud exercitation ullamco Laboris
                            consequat.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="review-box">
                                <div className="topbar">
                                    <figure>
                                        <img src={ReviewImg1} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="name">
                                        <h4 className="name">Sarah Williams</h4>
                                        <p>Entrepreneur </p>
                                    </div>
                                </div>
                                <p>
                                    Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                                    elit pellentesque habitant morbi tristique. Sem integer vitae
                                    justo eget magna fermentum iaculis eu non. Lacinia quis vel
                                    eros donec ac odio tempor orci dapibus.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="review-box">
                                <div className="topbar">
                                    <figure>
                                        <img src={ReviewImg2} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="name">
                                        <h4 className="name">Sarah Williams</h4>
                                        <p>Entrepreneur </p>
                                    </div>
                                </div>
                                <p>
                                    Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                                    elit pellentesque habitant morbi tristique. Sem integer vitae
                                    justo eget magna fermentum iaculis eu non. Lacinia quis vel
                                    eros donec ac odio tempor orci dapibus.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="review-box">
                                <div className="topbar">
                                    <figure>
                                        <img src={ReviewImg3} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="name">
                                        <h4 className="name">Sarah Williams</h4>
                                        <p>Entrepreneur </p>
                                    </div>
                                </div>
                                <p>
                                    Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                                    elit pellentesque habitant morbi tristique. Sem integer vitae
                                    justo eget magna fermentum iaculis eu non. Lacinia quis vel
                                    eros donec ac odio tempor orci dapibus.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="review-box">
                                <div className="topbar">
                                    <figure>
                                        <img src={ReviewImg4} alt="" className="img-fluid" />
                                    </figure>
                                    <div className="name">
                                        <h4 className="name">Sarah Williams</h4>
                                        <p>Entrepreneur </p>
                                    </div>
                                </div>
                                <p>
                                    Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                                    elit pellentesque habitant morbi tristique. Sem integer vitae
                                    justo eget magna fermentum iaculis eu non. Lacinia quis vel
                                    eros donec ac odio tempor orci dapibus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Client Review Sec End Here */}
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    );
};

export default Services;
