import React from "react";
import {
    EasyBefore, m12,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../assets/Css/Profile.css';
import ProfileTab from "./ProfileTab";

const LoanApplicaitonInterests = () => {

    let navigate = useNavigate();

    return (
        <div className="services Account">
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Easy Plan Sec Start Here */}
            <section className="easy-plan mt-5">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container mt-5">
                    <div className="row mb-3">
                        <div className="col-lg-12 text-left">
                            <p>Home <span className="fa fa-chevron-right chevron_right">  </span> Profile</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* accout section starts*/}
            <section className="profile_section">
                <div className="overflow_me_profile">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <ProfileTab />
                            </div>
                            <div className="col-lg-9">
                                <div className="InfoDetails">
                                <h3 className="heading_Account">My Loan Application Status</h3>
                                    <div className="InnerDivScrol">
                                        <div className="row">
                                            <div className="col-lg-7 my-2">
                                                <div className="d-flex">
                                                    <img className="product_img mr-4" src={m12} alt="product" />
                                                    <div >
                                                        <h3 className="subheading_Account">Horwin EK1</h3>
                                                        <p>Integer eget aliquet nibh praesent tristique magna sit amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 my-2">
                                                <div className="white_switch">
                                                    <p className="loan_status">Loan Status: </p>
                                                    <p className="Accpeted">Accepted</p>

                                                </div>
                                            </div>
                                            <div className="col-lg-7 my-2">
                                                <div className="d-flex">
                                                    <img className="product_img mr-4" src={m12} alt="product" />
                                                    <div >
                                                        <h3 className="subheading_Account">Horwin EK1</h3>
                                                        <p>Integer eget aliquet nibh praesent tristique magna sit amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 my-2">
                                                <div className="white_switch">
                                                    <p className="loan_status">Loan Status: </p>
                                                    <p className="Rejected">Rejected</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 my-2">
                                                <div className="d-flex">
                                                    <img className="product_img mr-4" src={m12} alt="product" />
                                                    <div >
                                                        <h3 className="subheading_Account">Horwin EK1</h3>
                                                        <p>Integer eget aliquet nibh praesent tristique magna sit amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 my-2">
                                                <div className="white_switch">
                                                    <p className="loan_status">Loan Status: </p>
                                                    <p className="Pending">Pending</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 my-2">
                                                <div className="d-flex">
                                                    <img className="product_img mr-4" src={m12} alt="product" />
                                                    <div >
                                                        <h3 className="subheading_Account">Horwin EK1</h3>
                                                        <p>Integer eget aliquet nibh praesent tristique magna sit amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 my-2">
                                                <div className="white_switch">
                                                    <p className="loan_status">Loan Status: </p>
                                                    <p className="Accpeted">Accepted</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 my-2">
                                                <div className="d-flex">
                                                    <img className="product_img mr-4" src={m12} alt="product" />
                                                    <div >
                                                        <h3 className="subheading_Account">Horwin EK1</h3>
                                                        <p>Integer eget aliquet nibh praesent tristique magna sit amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 my-2">
                                                <div className="white_switch">
                                                    <p className="loan_status">Loan Status: </p>
                                                    <p className="Rejected">Rejected</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 my-2">
                                                <div className="d-flex">
                                                    <img className="product_img mr-4" src={m12} alt="product" />
                                                    <div >
                                                        <h3 className="subheading_Account">Horwin EK1</h3>
                                                        <p>Integer eget aliquet nibh praesent tristique magna sit amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 my-2">
                                                <div className="white_switch">
                                                    <p className="loan_status">Loan Status: </p>
                                                    <p className="Pending">Pending</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* accout section ends*/}
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    );
};

export default LoanApplicaitonInterests;