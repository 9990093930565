import React from "react";
import {
	bannerBike,
	BannerSlide1,
	Benefit1,
	Benefit2,
	Benefit3,
	Benefit4,
	Easy1,
	Easy2,
	EasyBefore,
	LeftBefore,
	Product1,
	Product2,
	Product3,
	Product4,
	ReviewImg1,
	ReviewImg2,
	ReviewImg3,
	ReviewImg4,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const Home = () => {
	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}

			{/* banner Sec STart Here */}
			<section className="banner_sec">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="content-wrapper">
								<div className="title-wrapper">
									<h5 className="sub-title">
										<span>Welcome to Flit</span>
									</h5>
									<h2 className="main-title mt-4 pt-3">
										Let us <span className="green">Electrify</span> Everything
									</h2>
								</div>
								<p>
									Dignissim cras tincidunt lobortis feugiat vivamus at augue
									eget. Sociis natoque penatibus et magnis dis parturient. Nulla
									porttitor massa id neque aliquam vestibulum.
								</p>
								<div className="button-group">
									{/* <a href="#" className="flit-btn">
										Learn More
									</a> */}
									<Link className="flit-btn" to="/about"><span className="for-txt">Learn More</span> <span className="for-hover"></span></Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="image-box">
								<figure>
									<img src={bannerBike} alt="" className="img-fluid" />
								</figure>
							</div>
							<div className="recent-bikes">
								<div className="bike-box">
									<figure>
										<img src={BannerSlide1} alt="" className="img-fluid" />
									</figure>
									<Link to="#">Learn More</Link>
								</div>
								<div className="bike-box">
									<figure>
										<img src={BannerSlide1} alt="" className="img-fluid" />
									</figure>
									<Link to="#">Learn More</Link>
								</div>
								<div className="bike-box">
									<figure>
										<img src={BannerSlide1} alt="" className="img-fluid" />
									</figure>
									<Link to="#">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* banner Sec End Here */}

			{/* Easy Plan Sec Start Here */}
			<section className="easy-plan">
				<div className="right-before">
					<figure>
						<img src={EasyBefore} alt="" className="img-fluid" />
					</figure>
				</div>
				<div className="container">
					<div className="heading-wrapper">
						<h2>
							We Provide best E Vehicle <span className="icon">&</span>
							<br />
							<span className="colored">Easy plans To buy</span>
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco Laboris
							consequat.
						</p>
					</div>
					<div className="row row-one align-items-center">
						<div className="col-lg-6">
							<div className="content-wrapper universal-heading">
								<h3 className="title">
									We Provide best E Vehicle &
									<span className="colored"> Easy plans To buy</span>
								</h3>
								<p>
									Est velit egestas dui id ornare arcu odio ut sem. Congue nisi
									vitae suscipit tellus mauris a. Fringilla phasellus faucibus
									scelerisque eleifend donec pretium vulputate sapien. Mus
									mauris vitae ultricies leo integer malesuada. Amet venenatis
									urna cursus eget nunc scelerisque viverra. Mattis pellentesque
									id nibh tortor id. Lorem ipsum dolor sit amet consectetur
									adipiscing elit ut. Fusce id velit ut tortor.
								</p>
								<div className="buttob-group">
									{/* <a href="#" className="flit-btn">
										View Products
									</a> */}
									<Link className="flit-btn" to="/product1"><span className="for-txt">Get Loan</span><span className="for-hover"></span></Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="img-box">
								<figure>
									<img src={Easy1} alt="" className="img-fluid" />
								</figure>
							</div>
						</div>
					</div>
					<div className="row row-two align-items-center">
						<div className="col-lg-6">
							<div className="img-box">
								<figure>
									<img src={Easy2} alt="" className="img-fluid" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="content-wrapper universal-heading">
								<h3 className="title">
									We Provide best E Vehicle &
									<span className="colored"> Easy plans To buy</span>
								</h3>
								<p>
									Est velit egestas dui id ornare arcu odio ut sem. Congue nisi
									vitae suscipit tellus mauris a. Fringilla phasellus faucibus
									scelerisque eleifend donec pretium vulputate sapien. Mus
									mauris vitae ultricies leo integer malesuada. Amet venenatis
									urna cursus eget nunc scelerisque viverra. Mattis pellentesque
									id nibh tortor id. Lorem ipsum dolor sit amet consectetur
									adipiscing elit ut. Fusce id velit ut tortor.
								</p>
								<div className="buttob-group">
									{/* <a href="#" className="flit-btn">
										View Products
									</a> */}
									<Link className="flit-btn" to="/product1"><span className="for-txt">View Products</span><span className="for-hover"></span></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Easy Plan Sec End Here */}

			{/* Porcess Sec Start Here */}
			<section className="process-sec">
				<div className="left-before">
					<figure>
						<img src={LeftBefore} alt="" className="img-fluid" />
					</figure>
				</div>
				<div className="container">
					<div className="universal-heading">
						<h2>
							Have a look at <span className="colored">Process</span>
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco Laboris
							consequat.
						</p>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6 px-2">
							<div className="process-box">
								<div className="title">
									<h3>Step 1</h3>
									<h4>Lorem Ipsum</h4>
								</div>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore magna.
								</p>
								<div className="">
									<a href="#" className="lnk">
										Learn More
									</a>
									{/* <Link className="flit-btn" to="/about"><span className="for-txt">Learn More</span> <span className="for-hover"></span></Link> */}
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 px-2">
							<div className="process-box">
								<div className="title">
									<h3>Step 2</h3>
									<h4>Lorem Ipsum</h4>
								</div>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore magna.
								</p>
								<div className="">
									<a href="#" className="lnk">
										Learn More
									</a>
									{/* <Link className="flit-btn" to="/about"><span className="for-txt">Learn More</span> <span className="for-hover"></span></Link> */}
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 px-2">
							<div className="process-box">
								<div className="title">
									<h3>Step 3</h3>
									<h4>Lorem Ipsum</h4>
								</div>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore magna.
								</p>
								<div className="">
									<a href="#" className="lnk">
										Learn More
									</a>
									{/* <Link className="flit-btn" to="/about"><span className="for-txt">Learn More</span> <span className="for-hover"></span></Link> */}
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 px-2">
							<div className="process-box">
								<div className="title">
									<h3>Step 4</h3>
									<h4>Lorem Ipsum</h4>
								</div>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore magna.
								</p>
								<div className="">
									<a href="#" className="lnk">
										Learn More
									</a>
									{/* <Link className="flit-btn" to="/about"><span className="for-txt">Learn More</span> <span className="for-hover"></span></Link> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Porcess Sec End Here */}

			{/* Our Shop Sec Start Here */}
			<section className="shop_sec">
				<div className="right-before">
					<figure>
						<img src={EasyBefore} alt="" className="img-fluid" />
					</figure>
				</div>
				<div className="container">
					<div className="universal-heading">
						<h2>
							Browse Best E Vehicle
							<br /> from
							<span className="colored"> Our Shop</span>
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco Laboris
							consequat.
						</p>
					</div>
					<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="pills-Horwin-tab"
								data-toggle="pill"
								href="#pills-Horwin"
								role="tab"
								aria-controls="pills-Horwin"
								aria-selected="true"
							>
								Horwin
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="pills-Lorem-tab"
								data-toggle="pill"
								href="#pills-Lorem"
								role="tab"
								aria-controls="pills-Lorem"
								aria-selected="false"
							>
								Lorem Ipsum
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="pills-Adipiscing-tab"
								data-toggle="pill"
								href="#pills-Adipiscing"
								role="tab"
								aria-controls="pills-Adipiscing"
								aria-selected="false"
							>
								Adipiscing
							</a>
						</li>
					</ul>
					<div class="tab-content" id="pills-tabContent">
						<div
							class="tab-pane fade show active"
							id="pills-Horwin"
							role="tabpanel"
							aria-labelledby="pills-Horwin-tab"
						>
							<div className="row">
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product1} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product2} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product3} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product4} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="tab-pane fade"
							id="pills-Lorem"
							role="tabpanel"
							aria-labelledby="pills-Lorem-tab"
						>
							<div className="row">
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product1} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product2} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product3} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product4} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="tab-pane fade"
							id="pills-Adipiscing"
							role="tabpanel"
							aria-labelledby="pills-Adipiscing-tab"
						>
							<div className="row">
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product1} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product2} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product3} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="product-box">
										<div className="img-box">
											<figure>
												<img src={Product4} alt="" className="img-fluid" />
											</figure>
										</div>
										<div className="ti-1">
											<h3>
												{" "}

											</h3>

											<h4>HORWIN EK1 <br></br> <span className="color-read"><Link className="product-read" to="#">Read more</Link></span></h4>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Our Shop Sec End Here */}

			{/* Benefits Sec Start Here */}
			<section className="benefits">
				<div className="container">
					<div className="universal-heading">
						<h2>
							Benefits that <span className="colored">we Provide</span>
						</h2>
						<p>
							Est velit egestas dui id ornare arcu odio ut sem. Congue nisi
							vitae suscipit tellus mauris a. Fringilla phasellus faucibus
							scelerisque eleifend donec pretium vulputate sapien. Mus mauris
							vitae ultricies leo integer malesuada. Amet venenatis urna cursus
							eget nunc scelerisque viverra.
						</p>
					</div>
					<div className="benefits-box">
						<div className="row">
							<div className="col-lg-3 col-md-6">
								<div className="benefit-dv pr-3">
									<div className="icon-dv">
										<figure>
											<img src={Benefit1} alt="" className="img-fluid" />
										</figure>
									</div>
									<div className="txt">
										<h3>Best Quality</h3>
										<p>
											Est velit egestas dui id ornare arcu odio ut sem. Congue
											nisi vitae suscipit tellus.
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="benefit-dv pr-3">
									<div className="icon-dv">
										<figure>
											<img src={Benefit2} alt="" className="img-fluid" />
										</figure>
									</div>
									<div className="txt">
										<h3>Reliable Service</h3>
										<p>
											Est velit egestas dui id ornare arcu odio ut sem. Congue
											nisi vitae suscipit tellus.
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="benefit-dv pr-3">
									<div className="icon-dv">
										<figure>
											<img src={Benefit3} alt="" className="img-fluid" />
										</figure>
									</div>
									<div className="txt">
										<h3>24/7 Support</h3>
										<p>
											Est velit egestas dui id ornare arcu odio ut sem. Congue
											nisi vitae suscipit tellus.
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="benefit-dv pr-3">
									<div className="icon-dv">
										<figure>
											<img src={Benefit4} alt="" className="img-fluid" />
										</figure>
									</div>
									<div className="txt">
										<h3>Trusted Anywhere </h3>
										<p>
											Est velit egestas dui id ornare arcu odio ut sem. Congue
											nisi vitae suscipit tellus.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Benefits Sec End Here */}

			{/* Client Reviews Sec Start Here */}
			<section className="client-review">
				<div className="before">
					<figure>
						<img src={LeftBefore} alt="" className="img-fluid" />
					</figure>
				</div>
				<div className="container">
					<div className="universal-heading">
						<h2>
							Have a look at our <span className="colored">Client Reviews</span>
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco Laboris
							consequat.
						</p>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6 px-2">
							<div className="review-box">
								<div className="topbar">
									<figure>
										<img src={ReviewImg1} alt="" className="img-fluid" />
									</figure>
									<div className="name">
										<h4 className="name">Sarah Williams</h4>
										<p>Entrepreneur </p>
									</div>
								</div>
								<p>
									Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
									elit pellentesque habitant morbi tristique. Sem integer vitae
									justo eget magna fermentum iaculis eu non. Lacinia quis vel
									eros donec ac odio tempor orci dapibus.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 px-2">
							<div className="review-box">
								<div className="topbar">
									<figure>
										<img src={ReviewImg2} alt="" className="img-fluid" />
									</figure>
									<div className="name">
										<h4 className="name">Sarah Williams</h4>
										<p>Entrepreneur </p>
									</div>
								</div>
								<p>
									Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
									elit pellentesque habitant morbi tristique. Sem integer vitae
									justo eget magna fermentum iaculis eu non. Lacinia quis vel
									eros donec ac odio tempor orci dapibus.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 px-2">
							<div className="review-box">
								<div className="topbar">
									<figure>
										<img src={ReviewImg3} alt="" className="img-fluid" />
									</figure>
									<div className="name">
										<h4 className="name">Sarah Williams</h4>
										<p>Entrepreneur </p>
									</div>
								</div>
								<p>
									Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
									elit pellentesque habitant morbi tristique. Sem integer vitae
									justo eget magna fermentum iaculis eu non. Lacinia quis vel
									eros donec ac odio tempor orci dapibus.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 px-2">
							<div className="review-box">
								<div className="topbar">
									<figure>
										<img src={ReviewImg4} alt="" className="img-fluid" />
									</figure>
									<div className="name">
										<h4 className="name">Sarah Williams</h4>
										<p>Entrepreneur </p>
									</div>
								</div>
								<p>
									Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
									elit pellentesque habitant morbi tristique. Sem integer vitae
									justo eget magna fermentum iaculis eu non. Lacinia quis vel
									eros donec ac odio tempor orci dapibus.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Client Review Sec End Here */}
			{/* Footer */}
			<Footer />
			{/* Footer */}
		</>
	);
};

export default Home;
