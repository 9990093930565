import React from 'react';
import Header from '../../components/Header';
import "../../assets/Css/contact.css"
import Footer from '../../components/Footer';
import { EasyBefore } from '../../constant/Index';

const ContactUs = () => {
    return (
        <>
            <section className='contactus_page'>
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                {/* Header */}
                <Header />
                {/* Header */}
                <section class="breadcrums_sec">
                    <div className='container'>
                        <ul>
                            <li>Home</li>
                            <li><i className='fa fa-angle-right'></i></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </section>
                <section className='contactus_head'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-2'></div>
                            <div className='col-md-8'>
                                <h2 className='text-center'>
                                    <span className='colored'>Contact </span>Us
                                </h2>
                                <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Laboris consequat.</p>
                            </div>
                            <div className='col-md-2'></div>
                        </div>
                    </div>
                </section>
                <section className='contact_form_sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-2'></div>
                            <div className='col-md-8'>
                                <div className='form_start'>
                                    <form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="inputname" placeholder="First Name" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="inputlname" placeholder="Last Name" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <input type="email" class="form-control" id="inputEmail" placeholder="Email Address" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <input type="tel" class="form-control" id="inputpassword" placeholder="Phone Number" />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div class="form-group">
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder='Message' rows="3">

                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='button-group'>
                                                    <button className='btn'>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='col-md-2'></div>
                        </div>
                    </div>
                </section>
                {/* section contact description start here */}
                <section className='contact_desc_sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='contact_desc'>
                                    <div className='contact_img'>
                                        <i className='fa fa-envelope'></i>
                                    </div>
                                    <div className='contact_head'>
                                        <h4>Email us</h4>
                                    </div>
                                    <div className='contact_para'>
                                        <p>
                                            Email us for general queries, including marketing and partnership opportunities.
                                        </p>
                                    </div>
                                    <div className='contact_mail'>
                                        <a href='callto:012-3456-7890'>012-3456-7890</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='contact_desc'>
                                    <div className='contact_img'>
                                        <i className='fa fa-phone'></i>
                                    </div>
                                    <div className='contact_head'>
                                        <h4>Call us</h4>
                                    </div>
                                    <div className='contact_para'>
                                        <p>
                                            Call us to speak to a member of our team. We are always happy to help.
                                        </p>
                                    </div>
                                    <div className='contact_mail'>
                                        <a href='mailto:info@flit.co.uk'>info@flit.co.uk</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='contact_desc'>
                                    <div className='contact_img'>
                                        <i className='fa fa-map-marker'></i>
                                    </div>
                                    <div className='contact_head'>
                                        <h4>Our Head Office</h4>
                                    </div>
                                    <div className='contact_para'>
                                        <p>
                                            8182 Fulton Rd. Amirican Plain, <br />
                                            MA 02130
                                        </p>
                                    </div>
                                    <div className='contact_mail'>
                                        <a href="">Support Center</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section contact description end here */}
                {/* Footer */}
                <Footer />
                {/* Footer */}
            </section>
        </>
    )
}

export default ContactUs