import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { category, filter, scooter } from "../../constant/Index";
import "../../assets/Css/products.css";
import { Link, useNavigate } from "react-router-dom";
const Productagain = () => {
  let navigate = useNavigate();
  return (
    <>
      <Header />

      {/* Home and product sec  */}
      <section className="home-and-pro-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="home-and-product">
                <p>
                  <Link to="/">Home</Link> {">"} <Link to="/">Products</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of Home product sec  */}

      {/* Inner banner heading  */}
      <section className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-banner-head">
                <h2>
                  Our Premium <span>Products</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt <br></br> ut labore et dolore
                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                    exercitation <br></br>
                    ullamco Laboris consequat.
                  </p>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Inner heading  */}

      {/* Row for seacrh Bar  */}
      <section className="saerch-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="search-bar">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Search"
                  />
                </div>
                <div className="search-icon">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="category-pic-text for-flex-right">
                <span className="text-span">
                  <img className="img-fluid" src={category}></img> Category:
                </span>
                <div className="select-category">
                  <select name="cars" id="cars">
                    <option value="volvo"> Horwin</option>
                    <option value="saab"> Horwin</option>
                    <option value="opel"> Horwin</option>
                    <option value="audi"> Horwin</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="category-pic-text for-flex-center">
                <span className="text-span">
                  <img className="img-fluid" src={filter}></img> Filter:
                </span>
                <div className="select-category">
                  <select name="cars" id="cars">
                    <option value="volvo">None</option>
                    <option value="saab">None</option>
                    <option value="opel">None</option>
                    <option value="audi">None</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Of row Seacrh Bar  */}

      {/* Premiuim Product section  */}
      <section className="product-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img" onClick={()=>navigate("/innerservices")}>
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product-box">
                <div className="product-img">
                  <img className="img-fluid" src={scooter}></img>
                </div>
                <div className="product-price">
                  <div className="pro-text">
                    <h3>Horwin EK1 Electric Bike</h3>
                  </div>
                  <div className="price">
                    <p>Starting from</p>
                    <h5>250.00</h5>
                  </div>
                </div>
                <div className="pro-para">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>

                <div className="btn-color">
                  <div className="button-group product-btn">
                    <a href="#" className="flit-btn">
                      I am Interested
                    </a>
                    <span>
                      <div className="fo-nam">
                        <span>
                          <p>Colors:</p>
                        </span>

                        {/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
                        <input className="black" type="radio"></input>
                        <input className="white" type="radio"></input>
                        <input className="red" type="radio"></input>
                        <input className="blue" type="radio"></input>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="load-more-btn">
            <button>Load More</button>
          </div>
        </div>
      </section>
      {/* <End of Premium Product section  */}
      <Footer />
    </>
  );
};

export default Productagain;
