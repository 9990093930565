import React from "react";
import { Logo } from "../../constant/Index";
import "../../assets/Css/forgot.css";

const CreateNewPassword = () => {
	return (
		<>
			{/* <!-- Sign In Page Start Here --> */}
			<section className="signinSec p-0 d-flex align-items-center creat_password">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-4"></div>
						<div className="col-lg-4 p-0 pb-5 mb-5">
							<div className="logo-wrapper">
								<figure>
									<img src={Logo} alt="" />
								</figure>
							</div>
							<div className="signInBox">
								<div className="title">
									<h1>Create New Password</h1>
								</div>

								<div className="taglines pb-3">
									<h3>Type & confirm a strong & secure new Password</h3>
								</div>
								<form>
									<div className="form-group">
										<input
											type="email"
											className="form-control"
											placeholder="New Password"
										/>
									</div>
									<div className="form-group">
										<input
											type="email"
											className="form-control"
											placeholder="Confirm Password"
										/>
									</div>
									<div className="col-md-12 px-0 text-right mt-3">
										<button
											className="btn btn-secondary btnn signup-btn rounded-pill full"
											type="button"
										>
											Submit
										</button>
									</div>
									<div className="privacyDv">
										<p>Copyrights © 2022 EVX. All rights reserved.</p>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-4"></div>
					</div>
				</div>
			</section>
			{/* <!-- Sign In Page End Here --> */}
		</>
	);
};

export default CreateNewPassword;
