import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { EasyBefore, Logo, tick } from "../../constant/Index";
import "../../assets/Css/completeyourprofile.css";
import compprofile from "../../assets/img/completeprofile.png";
import bottomimg from "../../assets/img/botttom_img2.png";
import { useNavigate } from "react-router-dom";

const CompleteYourProfile = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* modal starts here */}
            <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="thank-you-wrapper">
                                <div className="thank-you-img-wrapper">
                                    <figure>
                                        <img src={tick} alt="" />
                                    </figure>
                                </div>
                                <div className="thank-you-content-wrapper">
                                    <h3>Thank You!</h3>
                                    <h4>For Signing Up in Flit</h4>
                                    <h6>Please Check Your email to continue</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal ends here */}
            {/* <!-- complete your profile Page Start Here --> */}
            {/* Header Start Here */}
            {/* Header End Here */}
            <section className="comprofile_Sec ">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4 p-0">
                            <div className="logo-wrapper">
                                <figure>
                                    <img src={Logo} alt="" />
                                </figure>

                            </div>
                            <div className="signInBox">
                                <div className="title">
                                    <h1>Complete Your Profile</h1>
                                </div>

                                <div className="taglines pb-3">
                                    <p>Please fill the information below to complete your profile</p>
                                </div>
                                <div className="compprofile_img">
                                    <label for="upload-photo">
                                        <figure>
                                            <img src={compprofile} className="img-fluid" alt="" />
                                        </figure>
                                        <label className="upload_btn" for="upload-photo">Upload Image</label>
                                    </label>
                                    <input type="file" id="upload-photo" name="" value="" />
                                </div>
                                <form>
                                    <div className="text-center">
                                        <h4>Personal Detail</h4>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="John"
                                            name="first name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=" Doe"
                                            name="last name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Johndoe@gmail.com"
                                            name="email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Contact Number"
                                            name="Contact Number"
                                        />
                                    </div>
                                    <div className="text-center my-4">
                                        <h4>Driving details </h4>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="D.O.B"
                                            name="D.O.B"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Age"
                                            name="Age"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address"
                                            name="Address"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Driver’s License Info"
                                            name="Driver’s License Info"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="RUT"
                                            name="RUT"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Company Details"
                                            name="company detail"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Revenue"
                                            name="revenue"
                                        />
                                    </div>
                                    <div className="col-md-12 px-0 text-right mt-5 pt-2">
                                        <button
                                            className="btn btn-secondary btnn signup-btn rounded-pill full"
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                    <div className="col-md-12 px-0 text-right mt-3">
                                        <button
                                            className="btn btn-secondary btnn signup-btn rounded-pill n-visible full"
                                            type="button"
                                            onClick={()=> navigate('/account')}
                                        >
                                            Skip for Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
                <div className="bottom_before">
                    <img src={bottomimg} className="img-fluid" alt="" />
                </div>
            </section>
            {/* <!-- Sign In Page End Here --> */}
            {/* Header Start Here */}
            {/* Header End Here */}
        </>
    )
}

export default CompleteYourProfile