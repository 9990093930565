import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Product1 } from "../constant/Index";
import About from "../Pages/About/About";
import ApplyForLoan from "../Pages/applyforloan/ApplyForLoan";
import ApplyForLoan2 from "../Pages/applyforloan2/ApplyForLoan";


import CreateNewPassword from "../Pages/auth/CreateNewPassword";
import ForgotPassword from "../Pages/auth/ForgotPassword";
import Login from "../Pages/auth/Login";
import Signup from "../Pages/auth/Signup";
import CompleteYourProfile from "../Pages/completeyourprofile/CompleteYourProfile";
import ContactUs from "../Pages/contact/ContactUs";
import Faqs from "../Pages/Faqs/Faqs";
import Home from "../Pages/Home/Home";
import Privacy from "../Pages/Privacypolicy/Privacy";
import Productagain from "../Pages/Products/Productagain";
import Productdetail from "../Pages/Products/Productdetail";
import Products from "../Pages/Products/Products";
import Account from "../Pages/ProfilePages/Account";
import DriverProfile from "../Pages/ProfilePages/DriverProfile";
import EditDriverDetail from "../Pages/ProfilePages/EditDriverDetail";
import EditPassword from "../Pages/ProfilePages/EditPassword";
import EditPersonalDetail from "../Pages/ProfilePages/EditPersonalDetail";
import LoanApplicaitonInterests from "../Pages/ProfilePages/LoanApplicationStatus";
import MyInterests from "../Pages/ProfilePages/MyInterests";
import InnerServices from "../Pages/Services/InnerServices";
import Services from "../Pages/Services/Services";
import Terms from "../Pages/Termsconditions/Terms";


const PublicRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/login" element={<Login />} />
					<Route path="/Signup" element={<Signup />} />
					<Route path="/ForgotPassword" element={<ForgotPassword />} />
					<Route path="/CreateNewPassword" element={<CreateNewPassword />} />
					<Route path="/contact" element={<ContactUs />} />

					<Route path="/product1" element={<Products/>} />
					<Route path="/productagain" element={<Productagain/>} />
					<Route path="/productdetail" element={<Productdetail/>} />
					
					{/* maaz routes */}
					<Route path="/services" element={<Services />} />
					<Route path="/innerservices" element={<InnerServices />} />
					<Route path="/faqs" element={<Faqs />} />
					<Route path="/account" element={<Account />} />
					<Route path="/edit-account" element={<EditPersonalDetail />} />
					<Route path="/edit-password" element={<EditPassword />} />
					<Route path="/driver-profile" element={<DriverProfile />} />
					<Route path="/edit-driver-profile" element={<EditDriverDetail />} />
					<Route path="/my-interest" element={<MyInterests />} />
					<Route path="/loan-application" element={<LoanApplicaitonInterests />} />
					<Route path="/terms" element={<Terms/>} />
					<Route path="/privacy" element={<Privacy/>} />
					<Route path="/about" element={<About/>} />
					{/* maaz routes */}
					<Route path="/applyforloan" element={<ApplyForLoan />} />
					<Route path="/applyforloan2" element={<ApplyForLoan />} />
					<Route path="/completeyourprofile" element={<CompleteYourProfile />} />

				
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default PublicRoutes;
