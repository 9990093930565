import React from "react";
import { Link } from "react-router-dom";
import "../../assets/Css/login.css";
import { Logo, signIn } from "../../constant/Index";

const Login = () => {
	return (
		<>
			{/* <!-- Sign In Page Start Here --> */}
			<section className="signinSec login p-0">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-2"></div>
						<div className="col-lg-4 col-md-6 p-0">
							<div className="logo-wrapper">
								<Link to="/">
									<figure>
										<img src={Logo} alt="" />
									</figure></Link>
							</div>
							<div className="signInBox">
								<div className="title">
									<h1>Sign In</h1>
								</div>


								<div className="taglines pb-3">
									<h3>
										Welcome back to <strong>Flit </strong>
										<br />
										Where You can get best<span> E Vehicle services</span>
									</h3>

									<p>
										Not a Member ?<Link to="/signup">Sign Up</Link>
									</p>
								</div>
								<form>
									<div className="form-group">
										<input
											type="email"
											className="form-control"
											placeholder="Email Address"
										/>
									</div>
									<div className="form-group">
										<input
											type="password"
											className="form-control"
											placeholder="Password"
										/>
									</div>
									<div className="col-md-12 px-0 text-right mt-3">
										<button
											onClick={(e) => {
												e.preventDefault();
												window.location.href = "/account";
											}}
											className="btn btn-secondary btnn signup-btn rounded-pill full"
											type="button"
										>
											Sign in
										</button>
									</div>
									<div className="actionsLink py-4">
										{/* <div className="remeberDv">
											<div className="form-group">
												<label for="remember" className="custom_check_box">Remember Me
													<input type="checkbox" id="remember" className="remember custom_check_box" hidden />
													<span></span>
												</label>
											</div>
										</div> */}
										<div class="form-group custom_checkbox">
											<input type="checkbox" id="agentcheck1"/>
											<label for="agentcheck1" className="ml-0">Remember Me</label>
										</div>
										<div className="forgotDv">
											<Link to="/ForgotPassword">Forgot Password ?</Link>
										</div>
									</div>
									{/* <div className="privacyDv">
										<p>Copyrights © 2022 Flit. All rights reserved.</p>
									</div> */}
								</form>
							</div>
						</div>

						<div className="col-lg-5 col-md-6 ml-auto p-0">
							<div className="img-Dv">
								<div className="imgBox">
									<figure>
										<img src={signIn} alt="" />
									</figure>
								</div>


								<div className="LogoBox">
									<figure>
										<a href="index.php">
											<img src="img/signinlogo.png" alt="" />
										</a>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Sign In Page End Here --> */}
		</>
	);
};

export default Login;
