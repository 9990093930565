import React from "react";
import Header from "../../components/Header";
import { EasyBefore, modal1, loan_confirm } from "../../constant/Index";
import horwin from "../../assets/img/horwin.png";
import dummytext from "../../assets/img/applyloan.png";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import "../../assets/Css/Style.css";
import "../../assets/Css/applyforloan.css";
const ApplyForLoan = () => {
  return (
    <>
      <section className="applyforloan_page">
        <div className="right-before">
          <figure>
            <img src={EasyBefore} alt="" className="img-fluid" />
          </figure>
        </div>
        {/* Header */}
        <Header />
        {/* Header */}
        <section class="breadcrums_sec">
          <div className="container">
            <ul>
              <li>Home</li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>Loan</li>
            </ul>
          </div>
        </section>
        <section className="contactus_head">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-2"></div> */}
              <div className="col-md-9 mx-auto">
                <h2 className="text-center">
                  Apply for <span className="colored">loan </span>
                </h2>
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  Laboris consequat.
                </p>
              </div>
              {/* <div className="col-md-2"></div> */}
            </div>
          </div>
        </section>
        <section className="contact_form_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="form_start">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputname"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputlname"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="tel"
                            class="form-control"
                            id="inputmobile"
                            placeholder="Mobile"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputarea"
                            placeholder="Area"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputcity"
                            placeholder="City"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputstate"
                            placeholder="State"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputrevenue"
                            placeholder="Revenues"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputdli"
                            placeholder="Driver License Info"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputdetail"
                            placeholder="Company Details"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option data-thumbnail="<img src={horwin} />">
                              Horwin EK1
                            </option>
                            <option>Horwin EK1</option>
                            <option>Horwin EK1</option>
                            <option>Horwin EK1</option>
                            <option>Horwin EK1</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="inputname"
                            placeholder="Message"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="applyloanimg">
                          <img src={dummytext} className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-group mt-4">
                          <input
                            type="text"
                            class="form-control"
                            id="inputname"
                            placeholder="Enter  the Above text"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-group custom_checkbox">
                          <input type="checkbox" id="agentcheck1" />
                          <label for="agentcheck1">
                            {" "}
                            I agree to three wheels agents calling me on my
                            provided number.
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-group custom_checkbox">
                          <input type="checkbox" id="agentcheck2" />
                          <label for="agentcheck2">
                            {" "}
                            I agree to Authorization for Credit Check
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="button-group">
                          <button data-toggle="modal" data-target="#exampleModalCenter2" type="button" className="btn">Submit</button>
                        </div>
                      </div>
                      {/* <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div className="confirm_text_start">
                                <div className="row">
                                  <div className="col-md-6">
                                    <figure>
                                      <img src={ loan_img } alt="" className="img-fluid" />
                                    </figure>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="confrim_text">
                                      <h5>Would You Like To</h5>
                                      <h4>Apply for Loan</h4>
                                      <p>Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing elit pellentesque habitant morbi tristique. </p>
                                      <button type="button" className="btn">Yes</button>
                                      <button type="button" className="btn gray">Not Now</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </section>
        {/* Footer */}
        <Footer />
        {/* Footer */}
      </section>

      {/* <button
        type="button"
        class="btn btn-primary"
        
      >
        Launch demo modal
      </button> */}

      <div
        class="modal applyforloan_page fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true"
      >
        <div class="modal-dialog apply-loan h-100" role="document">
          <div class="modal-content ">
            <div class="modal-body ">
              <div className="apply-loan h-100">
                <div className="row py-5">
                  <div className="col-md-5  ">
                    <div className="apply-loan-img">
                      <img className="img-fluid" src={loan_confirm}></img>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="modal-apply-loan-text">
                      <h2>Thank You!</h2>
                      <h3>For Applying For loan</h3>
                      <p>
                        Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing elit pellentesque habitant morbi tristique. 
                      </p>
                      <div className="two-btns-apply-loan">
                        <button className="bg-btn w-75" data-dismiss="modal">Continue To home page</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyForLoan;
