import React from "react";
import "../../assets/Css/signup.css";
import "../../assets/Css/login.css";
import { Logo, signIn, tick } from "../../constant/Index";
import { Link } from "react-router-dom";

const Signup = () => {
	return (
		<>
			{/* modal starts here */}
			<div
				className="modal fade"
				id="exampleModal"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<div className="thank-you-wrapper">
								<div className="thank-you-img-wrapper">
									<figure>
										<img src={tick} alt="" />
									</figure>
								</div>
								<div className="thank-you-content-wrapper">
									<h3>Thank You!</h3>
									<h4>For Signing Up in Flit</h4>
									<h6>Please Check Your email to continue</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* modal ends here */}
			{/* <!-- Sign In Page Start Here --> */}
			<section className="signinSec singin p-0">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-2"></div>
						<div className="col-lg-4 col-md-6 p-0">
							<div className="logo-wrapper">
								<figure>
									<img src={Logo} alt="" />
								</figure>
							</div>
							<div className="signInBox">
								<div className="title">
									<h1>Sign Up</h1>
								</div>
								<div className="taglines">
									<h3>Welcome to Flit</h3>
								</div>
								<ul className="nav nav-tabs" id="myTab" role="tablist">
									<li className="nav-item">
										<a
											className="nav-link active"
											id="home-tab"
											data-toggle="tab"
											href="#home"
											role="tab"
											aria-controls="home"
											aria-selected="true"
										>
											Customer
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="profile-tab"
											data-toggle="tab"
											href="#profile"
											role="tab"
											aria-controls="profile"
											aria-selected="false"
										>
											Business
										</a>
									</li>
								</ul>
								<div className="tab-content" id="myTabContent">
									<div
										className="tab-pane fade show active"
										id="home"
										role="tabpanel"
										aria-labelledby="home-tab"
									>
										<div className="taglines pt-2">
											<p>
												Already a Member ?<Link to="/login">Sign In</Link>
											</p>
										</div>
										<form action="/completeyourprofile">
											<div className="form-group">
												<input
													type="email"
													className="form-control"
													placeholder="First Name"
												/>
											</div>
											<div className="form-group">
												<input
													type="email"
													className="form-control"
													placeholder="Last Name"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Email Address"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Contact Number"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Password"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Confirm Password"
												/>
											</div>
											<div className="col-md-12 px-0 text-right mt-3">
												<button
													className="btn btn-secondary btnn signup-btn rounded-pill full"
													type="submit"
												>
													Sign Up
												</button>
											</div>
										</form>
									</div>
									<div
										className="tab-pane fade"
										id="profile"
										role="tabpanel"
										aria-labelledby="profile-tab"
									>
										<div className="taglines pt-2 ">
											<p className="mb-2">
												Already a Member ?<a href="/login">Sign In</a>
											</p>
										</div>
										<form action="/completeyourprofile">
											<div className="form-group row">
												<div className="col-lg-6 pr-2">
													<input
														type="email"
														className="form-control"
														placeholder="First Name"
													/>
												</div>
												<div className="col-lg-6 pl-2">
													<input
														type="email"
														className="form-control"
														placeholder="Last Name"
													/>
												</div>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Company Name"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Email Address"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Contact Number"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Password"
												/>
											</div>
											<div className="form-group">
												<input
													type="password"
													className="form-control"
													placeholder="Confirm Password"
												/>
											</div>
											<div className="col-md-12 px-0 text-right mt-3">
												<button
													className="btn btn-secondary btnn signup-btn rounded-pill full"
													type="submit"
												>
													Sign Up
												</button>
											</div>
										</form>
									</div>
								</div>
								<div className="privacyDv mt-3">
									<p>Copyrights © 2022 Flit. All rights reserved.</p>
								</div>
							</div>
						</div>

						<div className="col-lg-5 ml-auto col-md-6 p-0">
							<div className="img-Dv">
								<div className="imgBox">
									<figure>
										<img src={signIn} alt="" />
									</figure>
								</div>

								<div className="LogoBox">
									<figure>
										<a href="index.php">
											<img src="img/signinlogo.png" alt="" />
										</a>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Sign In Page End Here --> */}
		</>
	);
};

export default Signup;
