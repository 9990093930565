import React from "react";
import {
    EasyBefore,
    m7,
    m8,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../assets/Css/Profile.css';
import ProfileTab from "./ProfileTab";

const DriverProfile = () => {

    let navigate = useNavigate();

    return (
        <div className="services Account">
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Easy Plan Sec Start Here */}
            <section className="easy-plan mt-5">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container mt-5">
                    <div className="row mb-3">
                        <div className="col-lg-12 text-left">
                            <p>Home <span className="fa fa-chevron-right chevron_right">  </span> Profile</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* accout section starts*/}
            <section className="profile_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileTab />
                        </div>
                        <div className="col-lg-9">
                            <div className="InfoDetails">
                                <h3 className="heading_Account">Driver's Profile</h3>
                                <div className="row">
                                    <div className="col-lg-6 py-0">
                                        {/* <h3 className="subheading_Account mt-4">Personal Detail</h3> */}
                                    </div>
                                    <div className="col-lg-6 py-0 text-right">
                                        <p onClick={()=>{navigate('/edit-driver-profile')}} className="edit_account mb-0">Edit</p>
                                    </div>
                                    <div className="col-lg-6 mt-4  mb-2">
                                        <p className="field">DOB:</p>
                                    </div>
                                    <div className="col-lg-6 mt-4  mb-2">
                                        <p className="response">06-04-1980</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Age:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">42</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Address:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">72 Peninsula St. Parlin, NJ 08859</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Driver License Info:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">ID: 000378639</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">RUT:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">Lorem Ipsum</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Company Details:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">Lorem Ipsum</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Revenue:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">Lorem Ipsum</p>
                                    </div>
                                    {/* <div className="col-lg-6">
                                        <h3 className="subheading_Account mt-4">Password</h3>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <p className="edit_account mt-4 ">Edit</p>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <p className="field">Password:</p>
                                    </div>
                                    <div className="col-lg-8 mb-2">
                                        <p className="response_password">.........</p>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* accout section ends*/}


            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    );
};

export default DriverProfile;