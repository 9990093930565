import React from "react";
import {
    EasyBefore,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../assets/Css/Profile.css';
import ProfileTab from "./ProfileTab";

const EditDriverDetail = () => {

    let navigate = useNavigate();

    return (
        <div className="services Account">
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Easy Plan Sec Start Here */}
            <section className="easy-plan mt-5">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container mt-5">
                    <div className="row mb-3">
                        <div className="col-lg-12 text-left">
                            <p>Home <span className="fa fa-chevron-right chevron_right">  </span> Profile</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* accout section starts*/}
            <section className="profile_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileTab />
                        </div>
                        <div className="col-lg-9">
                            <div className="InfoDetails">
                                <h3 className="heading_Account">Edit Driver's details</h3>
                                <div className="row">

                                    <div className="col-lg-6 my-2">
                                        <input type='text' placeholder="D.O.B" className="inpt_field" />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <input type='text' placeholder="Age" className="inpt_field" />
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <input type='text' placeholder="Address" className="inpt_field" />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <input type='text' placeholder="Driver's License Info" className="inpt_field" />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <input type='text' placeholder="RUT" className="inpt_field" />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <input type='text' placeholder="Company Details " className="inpt_field" />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <input type='text' placeholder="Revenue" className="inpt_field" />
                                    </div>
                                    <div className="col-lg-6 mt-3">
                                        <a onClick={()=>{navigate('/account')}} href="#" class="flit-btn bt-prof-green">Save Changes</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* accout section ends*/}


            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    );
};

export default EditDriverDetail;