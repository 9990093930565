import React from "react";
import {
    EasyBefore,
    m7,
    m8,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../assets/Css/Profile.css';
import ProfileTab from "./ProfileTab";

const Account = () => {

    let navigate = useNavigate();

    return (
        <div className="services Account">
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Easy Plan Sec Start Here */}
            <section className="easy-plan mt-5">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container mt-5">
                    <div className="row mb-3">
                        <div className="col-lg-12 text-left">
                            <p>Home <span className="fa fa-chevron-right chevron_right">  </span> Profile</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* accout section starts*/}
            <section className="profile_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileTab />
                        </div>
                        <div className="col-lg-9">
                            <div className="InfoDetails">
                                <h3 className="heading_Account">Account</h3>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3 className="subheading_Account mt-4">Personal Detail</h3>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <p onClick={()=>{navigate('/edit-account')}}  className="edit_account mt-4 ">Edit</p>
                                    </div>
                                    <div className="col-lg-6 mt-4  mb-2">
                                        <p className="field">First Name:</p>
                                    </div>
                                    <div className="col-lg-6 mt-4  mb-2">
                                        <p className="response">John</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Last Name:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">Doe</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Email:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">Johndoe@gmail.com</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="field">Contact Number:</p>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <p className="response">+1-202-555-0196</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <h3 className="subheading_Account mt-4">Password</h3>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <p onClick={()=>{navigate('/edit-password')}} className="edit_account mt-4 ">Edit</p>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <p className="field">Password:</p>
                                    </div>
                                    <div className="col-lg-8 mb-2">
                                        <p className="response_password">.........</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* accout section ends*/}


            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    );
};

export default Account;