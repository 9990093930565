import React from "react";
import {
    EasyBefore,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import RemoveIcon from '@mui/icons-material/Remove';
// import '../../assets/Css/faqs.css';

const Faqs = () => {

    let navigate = useNavigate();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let arr = [
        {
            id: 1,
            heading: "Tristique magna sit amet purus gravida quis blandit. Lacus sed viverra tellus in haction.",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 2,
            heading: "Tellus id interdum velit laoreet id donec ultrices tincidunt. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 3,
            heading: "Platea dictumst vestibulum rhoncus est pellentesque. Aliquet porttitor lacus luctus accumsan tortor posuere",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 4,
            heading: "Dignissim cras tincidunt lobortis feugiat vivamus. Et ligula ullamcorper malesuada proin libero nunc",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 5,
            heading: "Etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Dui faucibus in ornare quam. ",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 6,
            heading: "Tristique magna sit amet purus gravida quis blandit. Lacus sed viverra tellus in haction.",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 7,
            heading: "Tellus id interdum velit laoreet id donec ultrices tincidunt. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 8,
            heading: "Platea dictumst vestibulum rhoncus est pellentesque. Aliquet porttitor lacus luctus accumsan tortor posuere",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
        {
            id: 9,
            heading: "Dignissim cras tincidunt lobortis feugiat vivamus. Et ligula ullamcorper malesuada proin libero nunc",
            inneeText: [
                { text: ` Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id.` },
            ]
        },
    ]

    return (
        <div className="Faq">
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Easy FAQS Sec Start Here */}
            <section className="easy-plan pb-0">
                <div className="right-before">
                    <figure>
                        <img src={EasyBefore} alt="" className="img-fluid" />
                    </figure>
                </div>
                <div className="container mt-5">
                    <div className="row mb-5">
                        <div className="col-lg-12 text-left">
                            <p>Home <span className="fa fa-chevron-right chevron_right">  </span> FAQs</p>
                        </div>
                    </div>
                    <div className="heading-wrapper pb-4">
                        <h2>
                            Our Frequently Asked  <span className="icon"></span>
                            {/* <br /> */}
                            <span className="colored">Questions</span>
                        </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Laboris consequat.
                        </p>
                    </div>
                </div>
            </section>
            {/* Easy FAQS Sec End Here */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {arr.map((item) => {
                                return (
                                    <Accordion key={item.id} className="acrdn_main my-3" expanded={expanded === `${item.id}`} onChange={handleChange(`${item.id}`)}>
                                        <AccordionSummary
                                            className="acrdn_summry"
                                            expandIcon={(expanded === `${item.id}`) ? <CloseIcon className="text-dark" /> :
                                                <AddIcon className="text-dark" />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <p className="faq_acrdn_heading">{item.heading}</p>
                                        </AccordionSummary>
                                        <AccordionDetails className="acrdn_details">
                                            {item?.inneeText?.map((itemInner, index) => {
                                                return (
                                                    <div className="d-flex" key={index + 100}>
                                                        <span className="fa fa-minus mr-3 faqs_dash"></span>
                                                        <p className="txt-all">{itemInner.text}</p>
                                                    </div>
                                                )
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>
                        <div class="col-lg-12 text-center mt_inner_faqs_button">
                            <div class="buttob-group">
                                <a href="#" class="flit-btn">Load More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    );
};

export default Faqs;