import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/Css/productdetail.css";
import { Link } from "react-router-dom";
import { detail, bene1, meter, play, modal1 } from "../../constant/Index";

const Productdetail = () => {
	return (
		<>
			<Header />

			{/* Home and product sec  */}
			<section className="home-and-pro-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="home-and-product">
								<p>
									<Link to="/">Home</Link>{">"}<Link to="/">Products detail</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* End of Home product sec  */}

			{/* Product detail section  */}
			<section className="product-detail-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="produtc-detail-img">
								<img className="img-fluid" src={detail}></img>
							</div>
						</div>

						<div className="col-md-6">
							<div className="product-detail-text">
								<div className="product-detail-head">
									<div className="product-de-he">
										<h3>Horwin EK1 </h3>
										<p>Intelligent batteries with a long life</p>
									</div>
									<div className="product-price">
										<h3>
											<span>$2150.00</span>
										</h3>
									</div>
								</div>
								<div className="product-description">
									<h4>Description:</h4>
									<p>
										Integer eget aliquet nibh praesent tristique magna sit amet.
										Rhoncus aenean vel elit scelerisque mauris pellentesque
										pulvinar pellentesque. Nam aliquam sem et tortor consequat
										id porta nibh. Quisque id diam vel quam elementum pulvinar
										etiam. Purus faucibus ornare suspendisse sed nisi lacus sed
										viverra. Eu volutpat odio facilisis mauris sit. Bibendum
										enim facilisis gravida neque convallis.
									</p>
								</div>

								<div className="product-description">
									<h4>Requirements:</h4>
									<ul>
										<li>Felis eget nunc lobortis mattis aliquam</li>
										<li>Ut enim blandit volutpat maecenas volutpat</li>
										<li>Blandit aliquam etiam eratet pharetra </li>
										<li>Pharetra massa massa ultricies.</li>
										<li>Lectus vestibulum mattis ullamcorper</li>
									</ul>
								</div>
							</div>

							<div className="row some-product-detail-row">
								<div className="col-md-6">
									<div className="fo-nam fo-colors">
										<span>
											<p>Colors:</p>
										</span>

										{/* <div className="color1 fill-color"> 
                              <div className="for-color-black"></div>
                            </div>
                            <div className="color2 fill-color"> </div>
                            <div className="color3 fill-color"> </div>
                            <div className="color4 fill-color"> </div> */}
										<input className="black" type="radio" name="color"></input>
										<input className="white abcdefgh" type="radio" name="color"></input>
										<input className="red" type="radio" name="color"></input>
										<input className="blue" type="radio" name="color"></input>
									</div>
									<div className="product-deatil-btn mt-3">
									<a className="flit-btn" data-toggle="modal" data-target="#exampleModalCenter"><span className="for-txt">I am Interested</span><span className="for-hover"></span></a>

									</div>
								</div>

								<div className="col-md-6">
									<div className="category-pic-text">
										<span className="spanss">Variants:</span>
										<div className="select-category">
											<select name="cars" id="cars">
												<option value="volvo">EK1 DS</option>
												<option value="saab">EK1 DS</option>
												<option value="opel">EK1 DS</option>
												<option value="audi"> EK1 DS</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* End of Product detail section  */}
			{/* Inner banner heading  */}
			<section className="inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="inner-banner-head ek">
								<h2>
									Features Of <span>Horwin EK 1</span>
									<p>
										Est velit egestas dui id ornare arcu odio ut sem. Congue
										nisi vitae suscipit tellus mauris a. Fringilla phasellus{" "}
										<br></br>
										faucibus scelerisque eleifend donec pretium vulputate
										sapien. Mus mauris vitae ultricies leo integer malesuada.{" "}
										<br></br>
										Amet venenatis urna cursus eget nunc scelerisque viverra.
									</p>
								</h2>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-4">
							<div className="img-all-car">
								<div className="img-car-pic">
									<figure>
										<img className="img-fluid" src={bene1}></img>
									</figure>
								</div>
								<h3>75 km</h3>
								<p>Max range</p>
							</div>
						</div>

						<div className="col-md-4">
							<div className="img-all-car">
								<div className="img-car-pic">
									<figure>
										<img className="img-fluid" src={bene1}></img>
									</figure>
								</div>
								<h3>95 km/h</h3>
								<p>Max Speed throtle</p>
							</div>
						</div>

						<div className="col-md-4">
							<div className="img-all-car">
								<div className="img-car-pic">
									<figure>
										<img className="img-fluid" src={bene1}></img>
									</figure>
								</div>
								<h3>36 Ah</h3>
								<p>1 Battery</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Inner heading  */}

			{/* Inner banner heading  */}
			<section className="inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="inner-banner-head horwin">
								<h2>
									Features Of Horwin E3 DS+
									<p>
										Est velit egestas dui id ornare arcu odio ut sem. Congue
										nisi vitae suscipit tellus mauris a. Fringilla phasellus{" "}
										<br></br>
										faucibus scelerisque eleifend donec pretium vulputate
										sapien. Mus mauris vitae ultricies leo integer malesuada.{" "}
										<br></br>
										Amet venenatis urna cursus eget nunc scelerisque viverra.
									</p>
								</h2>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-4">
							<div className="img-all-car">
								<div className="img-car-pic">
									<figure>
										<img className="img-fluid" src={bene1}></img>
									</figure>
								</div>
								<h3>75 km</h3>
								<p>Max range</p>
							</div>
						</div>

						<div className="col-md-4">
							<div className="img-all-car">
								<div className="img-car-pic">
									<figure>
										<img className="img-fluid" src={bene1}></img>
									</figure>
								</div>
								<h3>95 km/h</h3>
								<p>Max Speed throtle</p>
							</div>
						</div>

						<div className="col-md-4">
							<div className="img-all-car">
								<div className="img-car-pic">
									<figure>
										<img className="img-fluid" src={bene1}></img>
									</figure>
								</div>
								<h3>36 Ah</h3>
								<p>1 Battery</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Inner heading  */}

			<section className="inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="inner-banner-head horwin">
								<h2>
									Features Of Horwin EK 1
									<p>
										Est velit egestas dui id ornare arcu odio ut sem. Congue
										nisi vitae suscipit tellus mauris a. Fringilla phasellus{" "}
										<br></br>
										faucibus scelerisque eleifend donec pretium vulputate
										sapien. Mus mauris vitae ultricies leo integer malesuada.{" "}
										<br></br>
										Amet venenatis urna cursus eget nunc scelerisque viverra.
									</p>
								</h2>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<div className="pro-img">
								<img className="img-fluid" src={meter}></img>
								<div className="play-btn">
									<img className="img-fluid" src={play}></img>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Inner heading  */}

			<Footer />
			<div class="modal applyforloan_page fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
				<div class="modal-dialog apply-loan h-100" role="document">
					<div class="modal-content ">
						<div class="modal-body ">
							<div className="apply-loan h-100">
								<div className="row py-5">
									<div className="col-md-5  ">
										<div className="apply-loan-img">
										<img className="img-fluid" src={modal1}></img>
										</div>
									</div>

									<div className="col-md-6">
										<div className="modal-apply-loan-text">
										<h3>Would You Like To</h3>
										<h2>Apply for Loan</h2>
										<p>
										Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing elit pellentesque habitant morbi tristique. {" "}
										</p>
										<div className="two-btns-apply-loan">
										<button className="bg-btn" onClick={(e) => {
										e.preventDefault();
										window.location.href = "/applyforloan";
										}}>Yes</button>
										<button data-dismiss="modal">Not Now</button>
										</div>
										</div>
									</div>
								</div>
							</div>
					  	</div>
				  	</div>
        		</div>
			</div>
		</>
	);
};

export default Productdetail;
