import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Privacy = () => {
	return (
		<>
			<Header /> {/* Home and product sec  */}
			<section className="home-and-pro-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="home-and-product">
								<p>
									<Link to="/"> Home </Link> <span>></span>
									<Link to="/">Privacy Policy</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Home product sec  */}
			{/* Inner banner heading  */}
			<section className="inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="inner-banner-head">
								<h2>
									Privacy <span> Policy </span>
									{/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt <br></br> ut labore et dolore
                                magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation <br></br>
                                ullamco Laboris consequat.
                              </p> */}
								</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Inner heading  */}
			{/* Terms and condition  */}
			<section className="terms-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="terms-para">
								<p>
									Varius vel pharetra vel turpis nunc eget lorem.Viverra aliquet
									eget sit amet tellus cras adipiscing.Vitae elementum curabitur
									vitae nunc sed.Magna fringilla urna porttitor rhoncus dolor
									purus non enim praesent.Commodo odio aenean sed
									adipiscing.Blandit cursus risus at ultrices mi tempus
									imperdiet nulla malesuada.Donec pretium vulputate sapien nec
									sagittis.Ipsum a arcu cursus vitae congue mauris.Pretium quam
									vulputate dignissim suspendisse in est ante.Imperdiet nulla
									malesuada pellentesque elit eget gravida cum.Mauris sit amet
									massa vitae tortor.Et netus et malesuada fames ac turpis.Nulla
									pharetra diam sit amet nisl suscipit adipiscing.Sed felis eget
									velit aliquet sagittis id consectetur purus.Sagittis id
									consectetur purus ut faucibus.Eget nunc lobortis mattis
									aliquam faucibus purus in massa tempor.Nulla malesuada
									pellentesque elit eget gravida.Commodo quis imperdiet massa
									tincidunt nunc.
								</p>
								<p>
									Consectetur lorem donec massa sapien faucibus et.Sed viverra
									ipsum nunc aliquet bibendum enim facilisis gravida
									neque.Adipiscing bibendum est ultricies integer quis auctor
									elit sed.Ultrices dui sapien eget mi proin sed libero enim.Sed
									nisi lacus sed viverra tellus in .Eu nisl nunc mi ipsum
									faucibus vitae aliquet nec.Quis auctor elit sed vulputate mi
									sit amet mauris commodo.Morbi enim nunc faucibus a
									pellentesque sit amet porttitor.Massa sapien faucibus et
									molestie ac feugiat sed lectus vestibulum.Elit eget gravida
									cum sociis natoque penatibus et magnis.Ac feugiat sed lectus
									vestibulum mattis ullamcorper velit.Fermentum et sollicitudin
									ac orci phasellus egestas tellus rutrum.Viverra aliquet eget
									sit amet tellus cras adipiscing enim eu.Tempus urna et
									pharetra pharetra massa massa ultricies.Scelerisque fermentum
									dui faucibus in ornare quam viverra.Quis varius quam quisque
									id diam vel quam.Augue neque gravida in fermentum et.Aliquet
									porttitor lacus luctus accumsan.
								</p>
								<p>
									Lectus proin nibh nisl condimentum id venenatis a.Habitant
									morbi tristique senectus et netus et.Auctor elit sed vulputate
									mi sit.Feugiat vivamus at augue eget arcu dictum.Erat velit
									scelerisque in dictum non consectetur a erat.Amet nulla
									facilisi morbi tempus iaculis urna.Volutpat commodo sed
									egestas egestas fringilla phasellus faucibus.Ac turpis egestas
									sed tempus.Commodo nulla facilisi nullam vehicula ipsum a arcu
									cursus vitae.Interdum varius sit amet mattis vulputate enim
									nulla aliquet porttitor.Bibendum neque egestas congue quisque
									egestas.Euismod elementum nisi quis eleifend quam adipiscing
									vitae proin sagittis.At ultrices mi tempus imperdiet nulla
									malesuada pellentesque elit eget.Nibh venenatis cras sed felis
									eget velit aliquet sagittis.
								</p>
								<p>
									Duis ut diam quam nulla porttitor massa.Sed cras ornare arcu
									dui.Ut eu sem integer vitae justo eget magna fermentum
									iaculis.Dignissim sodales ut eu sem integer.Amet consectetur
									adipiscing elit ut aliquam purus sit.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="right-before"><figure><img src="/static/media/easy-before.e66c4407932666308811.png" alt="" class="img-fluid"/></figure></div>
			</section>
			
			{/* End Of Terms Conditon  */} <Footer />
		</>
	);
};

export default Privacy;
