import React from "react";
import {
    EasyBefore,
    m7,
    m8,
    bottomimg,
} from "../../constant/Index";
import "../../assets/Css/Style.css";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import '../../assets/Css/services.css';

const InnerServices = () => {

    let navigate = useNavigate();

    return (
        <>
            <div className="services">
                {/* Header */}
                <Header />
                {/* Header */}

                {/* Easy Plan Sec Start Here */}
                <section className="easy-plan">
                    <div className="right-before">
                        <figure>
                            <img src={EasyBefore} alt="" className="img-fluid" />
                        </figure>
                    </div>
                    <section class="home-and-pro-sec">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="home-and-product pt-5 mt-5">
                                        <p>
                                            <a href="/">Home</a> &gt; <a href="/">Products detail</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container mt-5 pt-2">
                        <div className="heading-wrapper pb-4">
                            <h2 className="innerservice_loremIpsumHeading">
                                Lorem ipsum dolor sit amet, consecte <br />
                                adipiscing elit, sed do eiusmod
                                {/* <span className="icon"></span>
                            <span className="colored">Services</span> */}
                            </h2>
                            <p className="innerService_p">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada. Faucibus purus in massa tempor nec feugiat nisl pretium fusce. Viverra tellus in hac habitasse platea dictumst. Sit amet porttitor eget dolor morbi non arcu. Turpis massa tincidunt dui ut ornare lectus sit. Bibendum neque egestas congue quisque egestas diam. Diam quam nulla porttitor massa id. Ultrices dui sapien eget mi proin sed libero enim sed. Leo vel fringilla est ullamcorper eget. Nisi porta lorem mollis aliquam ut.
                            </p>
                            <p className="innerService_p mt-4">
                                Ullamcorper velit sed ullamcorper morbi. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Felis imperdiet proin fermentum leo vel orci. Ut enim blandit volutpat maecenas volutpat blandit. Mauris sit amet massa vitae tortor. Vitae aliquet nec ullamcorper sit. Morbi tincidunt ornare massa eget. Molestie at elementum eu facilisis. Nunc pulvinar sapien et ligula ullamcorper. Turpis egestas maecenas pharetra convallis posuere morbi.
                            </p>
                        </div>
                        <div className="row my-5">
                            <div className="col-lg-6">
                                <img className="w-100" src={m7} alt="services" />
                            </div>
                            <div className="col-lg-6">
                                <div className="single_service_detail">
                                    <h2 className="innerservice_loremIpsumHeadingDown">
                                        Consecte adipiscing elit, sed
                                        egestas diam eiusmod
                                    </h2>
                                    <p>
                                        Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id. Lorem ipsum dolor sit amet consectetur adipiscing elit ut. Fusce id velit ut tortor.
                                    </p>
                                    <ul class="ul_pad_zero mt-3">
                                        <li class="ul_tick_round_white text-white">
                                            Felis eget nunc lobortis mattis aliquam
                                        </li>
                                        <li class="ul_tick_round_white text-white">
                                            Ut enim blandit volutpat maecenas volutpat
                                        </li>
                                        <li class="ul_tick_round_white text-white">
                                            Blandit aliquam etiam eratet pharetra
                                        </li>
                                        <li class="ul_tick_round_white text-white">
                                            Pharetra massa massa ultricies.
                                        </li>
                                        <li class="ul_tick_round_white text-white">
                                            Lectus vestibulum mattis ullamcorper
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row my-5">

                                <div className="col-lg-6 mt-5">
                                    <div className="single_service_detail">
                                        <h2 className="innerservice_loremIpsumHeadingDown">
                                            Consecte adipiscing elit, sed
                                            egestas diam eiusmod
                                        </h2>
                                        <p>
                                            Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra. Mattis pellentesque id nibh tortor id. Lorem ipsum dolor sit amet consectetur adipiscing elit ut. Fusce id velit ut tortor.
                                        </p>
                                        <p>
                                            Est velit egestas dui id ornare arcu odio ut sem. Congue nisi vitae suscipit tellus mauris a. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Mus mauris vitae ultricies leo integer malesuada. Amet venenatis urna cursus eget nunc scelerisque viverra.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <img className="w-100" src={m8} alt="services" />
                                </div>
                                <div className="col-lg-12 text-center mt_inner_services">
                                    <div class="buttob-group">
                                        {/* <a href="#" class="flit-btn">Contact Us</a> */}
                                        <Link className="flit-btn" to="/contact">Contact Us </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="bottom_before"><img src={bottomimg} class="img-fluid" alt=""/></div>
                </section>

                {/* Footer */}
                <Footer />
                {/* Footer */}
            </div>
        </>
    );
};

export default InnerServices;