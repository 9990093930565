import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  about,
  about1,
  about2,
  about3,
  about4,
  Benefit1,
  Benefit2,
  Benefit3,
  Benefit4,
  ReviewImg1,
  ReviewImg2,
  ReviewImg3,
  ReviewImg4,
  LeftBefore,
  big_shadow
} from "../../constant/Index";
import "../../assets/Css/about.css";

const About = () => {
  return (
    <>
      <Header />
      {/* Home and product sec  */}
      <section className="home-and-pro-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="home-and-product">
                <p>
                  <Link to="/">Home</Link> > <Link to="/">Services</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of Home product sec  */}

      {/* Inner banner heading  */}
      <section className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-banner-head">
                <h2>
                  About <span>Us</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt <br></br> ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation <br></br>
                  ullamco Laboris consequat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Inner heading  */}

      {/* About img section  */}
      <section className="about-img-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about-img">
                <img className="img-fluid" src={about}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End About img section  */}

      {/* Inner banner heading  */}
      <section className="inner-banner for-about-process pt-5 mt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-banner-head">
                <h2>
                  Our <span>Process</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt <br></br> ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Inner heading  */}
      <div className="beforexyz">
        <figure>
          <img src={big_shadow} alt="" className="img-fluid" />
        </figure>
      </div>
      {/* Our Process section  */}
      <section className="our-process-sec">
        <div className="container">
          <div className="row mt-5 py-3">
            <div className="col-md-6">
              <div className="all-procees-div">
                <div className="process-text">
                  <h3>Step 1</h3>
                  <h2>We Provide best E Vehicle &</h2>
                  <h2>
                    <span>Easy plans To buy</span>
                  </h2>
                  <p className="pt-3">
                    Est velit egestas dui id ornare arcu odio ut sem. Congue
                    nisi vitae suscipit tellus mauris a. Fringilla phasellus
                    faucibus scelerisque eleifend donec pretium vulputate
                    sapien. Mus mauris vitae ultricies leo integer malesuada.
                    Amet venenatis urna cursus eget nunc scelerisque viverra.
                    Mattis pellentesque id nibh tortor id. Lorem ipsum dolor sit
                    amet consectetur adipiscing elit ut. Fusce id velit ut
                    tortor.{" "}
                  </p>

                  <div className="get-loan-btn">
                    <Link to="/applyforloan"><span className="for-txt">Get Loan</span><span className="for-hover"></span></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="process-img">
                <img className="img-fluid w-100" src={about1} />
              </div>
            </div>
          </div>

          <div className="row about-second-row mt-5 p5-2">
            <div className="col-md-6">
              <div className="process-img">
                <img className="img-fluid" src={about2} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="all-procees-div">
                <div className="process-text">
                  <h3>Step 2</h3>
                  <h2>We Provide best E Vehicle &</h2>
                  <h2>
                    <span>Easy plans To buy</span>
                  </h2>
                  <p className="pt-3">
                    Est velit egestas dui id ornare arcu odio ut sem. Congue
                    nisi vitae suscipit tellus mauris a. Fringilla phasellus
                    faucibus scelerisque eleifend donec pretium vulputate
                    sapien. Mus mauris vitae ultricies leo integer malesuada.
                    Amet venenatis urna cursus eget nunc scelerisque viverra.
                    Mattis pellentesque id nibh tortor id. Lorem ipsum dolor sit
                    amet consectetur adipiscing elit ut. Fusce id velit ut
                    tortor.{" "}
                  </p>

                  <div className="get-loan-btn">
                    <Link to="/applyforloan"><span className="for-txt">Get Loan</span><span className="for-hover"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 pt-2">
            <div className="col-md-6">
              <div className="all-procees-div">
                <div className="process-text">
                  <h3>Step 3</h3>
                  <h2>We Provide best E Vehicle &</h2>
                  <h2>
                    <span>Easy plans To buy</span>
                  </h2>
                  <p className="pt-3">
                    Est velit egestas dui id ornare arcu odio ut sem. Congue
                    nisi vitae suscipit tellus mauris a. Fringilla phasellus
                    faucibus scelerisque eleifend donec pretium vulputate
                    sapien. Mus mauris vitae ultricies leo integer malesuada.
                    Amet venenatis urna cursus eget nunc scelerisque viverra.
                    Mattis pellentesque id nibh tortor id. Lorem ipsum dolor sit
                    amet consectetur adipiscing elit ut. Fusce id velit ut
                    tortor.{" "}
                  </p>

                  <div className="get-loan-btn">
                    <Link to="/applyforloan"><span className="for-txt">Get Loan</span><span className="for-hover"></span></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="process-img">
                <img className="img-fluid" src={about3} />
              </div>
            </div>
          </div>

          <div className="row about-second-row mt-5 pt-2">
            <div className="col-md-6">
              <div className="process-img">
                <img className="img-fluid" src={about4} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="all-procees-div">
                <div className="process-text">
                  <h3>Step 4</h3>
                  <h2>We Provide best E Vehicle &</h2>
                  <h2>
                    <span>Easy plans To buy</span>
                  </h2>
                  <p className="pt-3">
                    Est velit egestas dui id ornare arcu odio ut sem. Congue
                    nisi vitae suscipit tellus mauris a. Fringilla phasellus
                    faucibus scelerisque eleifend donec pretium vulputate
                    sapien. Mus mauris vitae ultricies leo integer malesuada.
                    Amet venenatis urna cursus eget nunc scelerisque viverra.
                    Mattis pellentesque id nibh tortor id. Lorem ipsum dolor sit
                    amet consectetur adipiscing elit ut. Fusce id velit ut
                    tortor.{" "}
                  </p>

                  <div className="get-loan-btn">
                    <Link to="/applyforloan"><span className="for-txt">Get Loan</span><span className="for-hover"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Our Process section  */}

      {/* Inner banner heading  */}
      <section className="inner-banner for-about-benefit">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-banner-head">
                <h2>
                  Benefits that <span>we Provide</span>
                </h2>
                <p>
                  Est velit egestas dui id ornare arcu odio ut sem. Congue
                  nisi vitae suscipit tellus mauris a. Fringilla phasellus{" "}
                  <br></br>
                  faucibus scelerisque eleifend donec pretium vulputate
                  sapien. Mus mauris vitae ultricies leo integer malesuada.{" "}
                  <br></br>
                  Amet venenatis urna cursus eget nunc scelerisque viverra.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Inner heading  */}

      {/* Benefits Sec Start Here */}
      <section className="benefits">
        <div className="container">
          {/* <div className="universal-heading">
                        <h2>
                            <span className="colored">Benefits</span> that we Provide
                        </h2>
                        <p>
                            Est velit egestas dui id ornare arcu odio ut sem. Congue nisi
                            vitae suscipit tellus mauris a. Fringilla phasellus faucibus
                            scelerisque eleifend donec pretium vulputate sapien. Mus mauris
                            vitae ultricies leo integer malesuada. Amet venenatis urna cursus
                            eget nunc scelerisque viverra.
                        </p>
                    </div> */}
          <div className="benefits-box">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="benefit-dv">
                  <div className="icon-dv">
                    <figure>
                      <img src={Benefit1} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="txt">
                    <h3>Best Quality</h3>
                    <p>
                      Est velit egestas dui id ornare arcu odio ut sem. Congue
                      nisi vitae suscipit tellus.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="benefit-dv">
                  <div className="icon-dv">
                    <figure>
                      <img src={Benefit2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="txt">
                    <h3>Reliable Service</h3>
                    <p>
                      Est velit egestas dui id ornare arcu odio ut sem. Congue
                      nisi vitae suscipit tellus.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="benefit-dv">
                  <div className="icon-dv">
                    <figure>
                      <img src={Benefit3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="txt">
                    <h3>24/7 Support</h3>
                    <p>
                      Est velit egestas dui id ornare arcu odio ut sem. Congue
                      nisi vitae suscipit tellus.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="benefit-dv">
                  <div className="icon-dv">
                    <figure>
                      <img src={Benefit4} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="txt">
                    <h3>Trusted Anywhere </h3>
                    <p>
                      Est velit egestas dui id ornare arcu odio ut sem. Congue
                      nisi vitae suscipit tellus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Benefits Sec End Here */}

      {/* Inner banner heading  */}
      <section className="inner-banner for-about-benefit">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-banner-head">
                <h2>
                  Have a look at our <span>Client Reviews</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt <br></br> ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation <br></br>
                    ullamco Laboris consequat.
                  </p>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Inner heading  */}

      {/* Client Reviews Sec Start Here */}
      <section className="client-review">
        <div className="beforeabcd">
          <figure>
            <img src={LeftBefore} alt="" className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          {/* <div className="universal-heading">
                        <h2>
                            Have a look at our <span className="colored">Client Reviews</span>
                        </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                            enim ad minim veniam, quis nostrud exercitation ullamco Laboris
                            consequat.
                        </p>
                    </div> */}
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="review-box">
                <div className="topbar">
                  <figure>
                    <img src={ReviewImg1} alt="" className="img-fluid" />
                  </figure>
                  <div className="name">
                    <h4 className="name">Sarah Williams</h4>
                    <p>Entrepreneur </p>
                  </div>
                </div>
                <p>
                  Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                  elit pellentesque habitant morbi tristique. Sem integer vitae
                  justo eget magna fermentum iaculis eu non. Lacinia quis vel
                  eros donec ac odio tempor orci dapibus.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="review-box">
                <div className="topbar">
                  <figure>
                    <img src={ReviewImg2} alt="" className="img-fluid" />
                  </figure>
                  <div className="name">
                    <h4 className="name">Sarah Williams</h4>
                    <p>Entrepreneur </p>
                  </div>
                </div>
                <p>
                  Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                  elit pellentesque habitant morbi tristique. Sem integer vitae
                  justo eget magna fermentum iaculis eu non. Lacinia quis vel
                  eros donec ac odio tempor orci dapibus.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="review-box">
                <div className="topbar">
                  <figure>
                    <img src={ReviewImg3} alt="" className="img-fluid" />
                  </figure>
                  <div className="name">
                    <h4 className="name">Sarah Williams</h4>
                    <p>Entrepreneur </p>
                  </div>
                </div>
                <p>
                  Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                  elit pellentesque habitant morbi tristique. Sem integer vitae
                  justo eget magna fermentum iaculis eu non. Lacinia quis vel
                  eros donec ac odio tempor orci dapibus.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="review-box">
                <div className="topbar">
                  <figure>
                    <img src={ReviewImg4} alt="" className="img-fluid" />
                  </figure>
                  <div className="name">
                    <h4 className="name">Sarah Williams</h4>
                    <p>Entrepreneur </p>
                  </div>
                </div>
                <p>
                  Vitae semper quis lectus nulla at volutpat diam ut. Adipiscing
                  elit pellentesque habitant morbi tristique. Sem integer vitae
                  justo eget magna fermentum iaculis eu non. Lacinia quis vel
                  eros donec ac odio tempor orci dapibus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Client Review Sec End Here */}
      <Footer />
    </>
  );
};

export default About;
