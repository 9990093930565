import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { m11, m9 } from '../../constant/Index';

const ProfileTab = () => {
    let navigate = useNavigate();
    let location = useLocation();
    console.log(location.pathname);

    return (
        <div className='ProfileTab'>
            <div className='image_div'>
                <img src={m9} width={100} />
                <div class="change_profile f-mon-reg">
                    <label for="fileUpload">
                        <div className='bg-img-upload-camera'>
                            {/* <p class="hvr_p">
                                Change Image
                            </p> */}
                        </div>
                    </label>
                    <input hidden id="fileUpload" type="file" accept="image/*" />
                </div>
            </div>
            <h4 className='name_person mb-4'>John Doe</h4>
            <div onClick={()=>{navigate('/account')}} className='flx_dividr '>
                <p className={location.pathname === "/account" ? null : "no_active"}>Account</p> <img className='mt-1' height={15} width={15} src={m11}/>
            </div>
            <div className='brdr-btm-drk'></div>
            <div onClick={()=>{navigate('/driver-profile')}}  className='flx_dividr '>
                <p className={location.pathname === "/driver-profile" ? null : "no_active"}>Driver's Profile</p> <img className='mt-1' height={15} width={15} src={m11}/>
            </div>
            <div className='brdr-btm-drk'></div>
            <div onClick={()=>{navigate('/my-interest')}}  className='flx_dividr '>
                <p className={location.pathname === "/my-interest" ? null : "no_active"}>My Interest</p> <img className='mt-1' height={15} width={15} src={m11}/>
            </div>
            <div className='brdr-btm-drk'></div>
            <div onClick={()=>{navigate('/loan-application')}}  className='flx_dividr '>
                <p className={location.pathname === "/loan-application" ? null : "no_active"}>Loan Application status</p> <img className='mt-1' height={15} width={15} src={m11}/>
            </div>
            <div className='brdr-btm-drk'></div>
            <div onClick={()=>{navigate('/')}}  className='flx_dividr '>
                <p className='no_active'>Logout</p> <img className='mt-1' height={15} width={15} src={m11}/>
            </div>
        </div>
    );
};

export default ProfileTab;